import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import OpenHomely_Logo from 'assets/OpenHomely_Logo.svg';
import Button from 'components/common/Button/Button.jsx';
import Logo from 'assets/logo.png';
import 'components/Header/Header.scss';

//<Link to="/dashboard"><Button title="Dashboard" outline /></Link>

const Header = ({ name }) => (
    <div className="Header">
        <Link to={name ? '/dashboard' : '/'}>
            <div className="HeaderLogo">
                <img className="HeaderLogoImg" src={OpenHomely_Logo} title="OpenHomelyLogo" alt="OpenHomely" />
            </div>
        </Link>
        <div className="HeaderRight">
            { name ? <span className="HeaderRightWelcome">{name}</span> : <Link to="/login"><Button title="Login" outline /></Link> }
        </div>
    </div>
);

Header.propTypes = {
    name: PropTypes.string,
};

Header.defaultProps = {
    name: '',
};

export default Header;
