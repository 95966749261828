import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button/Button.jsx';
import './Payment.scss';

const Payment = ({ success, loading, startPaymentSession }) => (
    <div className="PaymentWrapper">
        <div className="PaymentInner">
            <div className="PaymentTitle">{success ? "Sit tight, we're preparing your account." : "Subscribe to take your business to the next level."}</div>
            {!success && <Button className="PaymentButton" title="Subscribe" onClick={startPaymentSession} />}
        </div>
    </div>
);

Payment.propTypes = {
    success: PropTypes.bool,
    loading: PropTypes.bool,
    startPaymentSession: PropTypes.func,
};

export default Payment;
