import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {config, useSpring, animated} from 'react-spring';
import { withFormik } from 'formik';
import Button from 'components/common/Button/Button.jsx';
import Home from 'assets/home.jpg';
import 'components/Login/Login.scss';

const Login = ({
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    error
}) => {

    const [submitted, setSubmitted] = useState(false);

    const formAnimation = useSpring({
        to: {
            transform: 'translate(0%, 0%)',
            opacity: 1,
        },
        from: {
            transform: 'translate(0%, 5%)',
            opacity: 0,
        },
        delay: 0,
        config: config.default
    });

    return (
        <div className="Login">
            <animated.img className="LoginBackgroundImage" src={Home} style={formAnimation} />
            <animated.div className="LoginFormContainer" style={formAnimation} >
                <div className="LoginFormTitle">Login</div>
                <form className="LoginForm" onSubmit={handleSubmit}>
                    <div className="LoginFormInputTitle">Email</div>
                    <input
                        className="LoginFormInput"
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        name="email"
                    />
                    {errors.email && touched.email && <div className="LoginFormInputFeedback">{errors.email}</div>}
                    <div className="LoginFormInputTitle">Password</div>
                    <input
                        className="LoginFormInput"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        name="password"
                    />
                    {errors.password && touched.password && <div className="LoginFormInputFeedback">{errors.password}</div>}
                    {submitted && error && error.response && <div className="SignupFormInputFeedback">{error.response.data}</div>}
                    <button className="LoginFormButton" type="submit" onClick={() => setSubmitted(true)}><Button title="Login"/></button>
                    <div className="LoginFormForgotPassword"><Link to="/resetpassword">Forgot Password?</Link></div>
                </form>
            </animated.div>
        </div>
    );
};

const LoginForm = withFormik({
    mapPropsToValues: () => ({ email: '', password: '' }),

    validate: values => {
        const errors = {};

        if (!values.email) {
            errors.email = 'Your email is required.';
        }

        if (!values.password) {
            errors.password = 'Your password is required.';
        }

        return errors;
    },

    handleSubmit: (values, { setSubmitting, props }) => {
        props.login(values.email, values.password);
        setSubmitting(false);
    },

    displayName: 'LoginForm',
})(Login);

Login.propTypes = {
    name: PropTypes.string,
    errors: PropTypes.any,
    handleSubmit: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
};

Login.defaultProps = {
    name: '',
};

export default LoginForm;
