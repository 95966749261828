import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSpring, animated, config } from 'react-spring';
import Button from 'components/common/Button/Button.jsx';
import MyInfo from 'components/MyInfo/MyInfo.jsx';
import Dialog from 'components/common/Dialog/Dialog.jsx';
import Modal from 'components/common/Modal/Modal.jsx';
import 'components/Settings/Settings.scss';

const Settings = ({
    logout,
    unsubscribe,
    deleteAccount,
}) => {

    const [unsubscribeModalOpen, setUnsubscribeModalOpen] = useState(false);
    const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);

    const settingSectionAnimation = useSpring({
        to: {
            transform: 'translate(0%, 0%)',
            opacity: 1,
        },
        from: {
            transform: 'translate(0%, -5%)',
            opacity: 0,
        },
        delay: 0,
        config: config.default
    });

    return (
        <div className="Settings">
            <div className="SettingsTop">
                <div className="SettingsTitle">Settings</div>
                <Link to="/dashboard"><Button title="View Dashboard" /></Link>
            </div>
            <animated.section className="SettingsSection" style={settingSectionAnimation} >
                <div className="SettingsSectionTitle">Subscription</div>
                <div className="SettingsSectionList">
                    <Button className="SettingsButton" title="Cancel Subscription" onClick={() => setUnsubscribeModalOpen(true)} outline />
                </div>
            </animated.section>
            <Modal isOpen={unsubscribeModalOpen} handleClose={() => setUnsubscribeModalOpen(false)}>
                <Dialog
                    title="Are you sure?"
                    subtitle="By unsubscribing you will retain access to your account until the next billing cycle, at which you will lose access to your account until you resubscribe."
                    buttonTitle="Unsubscribe"
                    onClick={() => {
                        unsubscribe();
                        setUnsubscribeModalOpen(false);
                    }}
                />
            </Modal>
            <animated.section className="SettingsSection" style={settingSectionAnimation} >
                <div className="SettingsSectionTitle">Account</div>
                <div className="SettingsSectionList">
                    <Button className="SettingsButton" title="Logout" onClick={logout} />
                    <Button className="SettingsButton" title="Delete Account" onClick={() => setDeleteAccountModalOpen(true)} outline />
                    <Link to="/resetpassword"><Button className="SettingsButton" title="Reset Password" outline /></Link>
                </div>
            </animated.section>
            <Modal isOpen={deleteAccountModalOpen} handleClose={() => setDeleteAccountModalOpen(false)}>
                <Dialog
                    title="Are you sure?"
                    subtitle="By deleting your account, all data associated with your account will be deleted and you will not be refunded for any remaining time in your current billing cycle."
                    buttonTitle="Delete Account"
                    onClick={() => {
                        deleteAccount();
                        setDeleteAccountModalOpen(false);
                    }}
                />
            </Modal>
        </div>
    );
};

Settings.propTypes = {
    logout: PropTypes.func.isRequired,
    unsubscribe: PropTypes.func.isRequired,
};

export default Settings;
