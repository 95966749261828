import axios from 'axios';

export const unsubscribe = () => {
    const url = '/payment/unsubscribe';

    const requestConfig = {
        method: 'post',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const startPaymentSession = () => {
    const url = '/payment/startsession';

    const requestConfig = {
        method: 'post',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};
