import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { tokenLogin } from "../actions/auth";

const AuthContainer = (props) => {
    const {
        tokenLogin,
        children,
        jwt,
        subscribed
    } = props;

    useEffect(() => {
        if (jwt) {
            tokenLogin(jwt);
        }
    }, [subscribed]);

    return children;
};

const mapStateToProps = (state) => {
    return {
        name: state.auth.name,
        subscribed: state.auth.subscribed,
        email: state.auth.email,
        jwt: state.auth.jwt,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        tokenLogin,
    }, dispatch);
};

AuthContainer.propTypes = {
    children: PropTypes.node,
    tokenLogin: PropTypes.func.isRequired,
};

AuthContainer.defautProps = {
    jwt: ""
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer);
