import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {useSpring, animated, config} from 'react-spring';
import "components/common/Modal/Modal.scss";

const Modal = ({
    handleClose,
    isOpen,
    children
}) => {
    const [isOpenChanging, setIsOpenChanging] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsOpenChanging(false);
        }, 500)
    }, [isOpen]);

    const handleCloseIntercept = () => {
        setIsOpenChanging(true);
        handleClose();
    };

    const bgAnimation = useSpring({
        backgroundColor: isOpen  ? 'rgba(19, 37, 55, 0.3)' : 'rgba(19, 37, 55, 0)',
        delay: 0,
        config: config.default
    });

    const modalAnimation = useSpring({
        transform: isOpen ? 'translate(-50%, -50%)' : 'translate(-50%, 0%)',
        opacity: isOpen ? 1 : 0,
        delay: 0,
        config: config.default
    });

    if (isOpen || isOpenChanging) {
        return ReactDOM.createPortal((
                <animated.div className="ModalContainer" style={bgAnimation} onClick={handleCloseIntercept}>
                    <animated.div className="Modal" style={modalAnimation} onClick={(e) => e.stopPropagation()}>
                        {children}
                    </animated.div>
                </animated.div>
            ), document.getElementById('body'));
    }
    return null;
};

Modal.propTypes = {
    handleClose: PropTypes.func,
    isOpen: PropTypes.bool,
    children: PropTypes.node,
};

Modal.defaultProps = {
    handleClose: () => {},
    isOpen: false,
    children: null,
};

export default Modal;
