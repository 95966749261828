import {
    GET_HOMES_REQUEST,
    GET_HOMES_ERROR,
    GET_HOMES_SUCCESS,
    ADD_HOME_REQUEST,
    ADD_HOME_ERROR,
    ADD_HOME_SUCCESS,
    DELETE_HOME_REQUEST,
    DELETE_HOME_ERROR,
    DELETE_HOME_SUCCESS,
    UPDATE_HOME_REQUEST,
    UPDATE_HOME_ERROR,
    UPDATE_HOME_SUCCESS,
    SEND_FOLLOW_UP_REQUEST,
    SEND_FOLLOW_UP_ERROR,
    SEND_FOLLOW_UP_SUCCESS,
} from 'actions/homes.js';

export const initialState = {
    homes: [],
    loading: false,
    error: null
};

export default function homesReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_HOMES_REQUEST:
        case ADD_HOME_REQUEST:
        case DELETE_HOME_REQUEST:
        case UPDATE_HOME_REQUEST:
        case SEND_FOLLOW_UP_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_HOMES_ERROR:
        case ADD_HOME_ERROR:
        case DELETE_HOME_ERROR:
        case UPDATE_HOME_ERROR:
        case SEND_FOLLOW_UP_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ADD_HOME_SUCCESS:
        case DELETE_HOME_SUCCESS:
        case UPDATE_HOME_SUCCESS:
        case SEND_FOLLOW_UP_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case GET_HOMES_SUCCESS:
            return {
                ...state,
                homes: action.payload.data,
                loading: false,
                error: null,
            };
        default:
            return state;
    }
}
