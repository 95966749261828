import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { animated, config, useSpring } from 'react-spring';
import Button from 'components/common/Button/Button.jsx';
import Modal from 'components/common/Modal/Modal.jsx';
import AddUpdateHome from 'components/Homes/AddUpdateHome.jsx';
import Dialog from 'components/common/Dialog/Dialog.jsx';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import 'components/Homes/Homes.scss';

const Homes = ({ homes, addHome, updateHome, deleteHome }) => {
    const [addHomeModalOpen, setAddHomeModalOpen] = useState(false);
    const [targetHomeId, setTargetHomeId] = useState(null);
    const [updateHomeModalOpen, setUpdateHomeModalOpen] = useState(false);
    const [deleteHomeModalOpen, setDeleteHomeModalOpen] = useState(false);
    const targetHome = homes.find((home) => home.homeID === targetHomeId);

    const homeCardAnimation = useSpring({
        to: {
            transform: 'translate(0%, 0%)',
            opacity: 1,
        },
        from: {
            transform: 'translate(0%, -2%)',
            opacity: 0,
        },
        delay: 0,
        config: config.default
    });

    return (
        <div className="HomesList">
            <div className="HomeCardAdd">
                <Button title="+ Add Home" onClick={() => setAddHomeModalOpen(!addHomeModalOpen)}/>
            </div>
            <Modal
                isOpen={addHomeModalOpen || updateHomeModalOpen}
                handleClose={() => {
                    setAddHomeModalOpen(false);
                    setUpdateHomeModalOpen(false);
                }}
            >
                <AddUpdateHome
                    addHome={(title, link) => {
                        addHome(title, link);
                        setAddHomeModalOpen(false);
                    }}
                    updateHome={(title, link) => {
                        updateHome(targetHomeId, title, link);
                        setUpdateHomeModalOpen(false);
                    }}
                    link={targetHomeId && targetHome ? targetHome.link : ""}
                    title={targetHomeId && targetHome ? targetHome.title : ""}
                    isUpdate={updateHomeModalOpen}
                />
            </Modal>
            <Modal isOpen={deleteHomeModalOpen} handleClose={() => setDeleteHomeModalOpen(false)}>
                <Dialog
                    title="Are you sure?"
                    subtitle="By deleting a home you will also delete all leads associated with it."
                    buttonTitle="Delete"
                    onClick={() => {
                        deleteHome(targetHomeId);
                        setDeleteHomeModalOpen(false);
                    }}
                />
            </Modal>
            {homes.map((home, index) => (
                <animated.div className="HomeCard" style={homeCardAnimation} key={index}>
                    <div className="HomeCardTop">
                        <span className="HomeCardTitle">{home.title}</span>
                        <a href={home.link} target="_blank"><span
                            className="HomeCardSubtitle">{home.link}</span></a>
                    </div>
                    <div className="HomeCardBottom">
                        <div className="HomeCardBottomLeft">
                            <Link className="HomeCardBottomLeftStart" to={`/dashboard/${home.homeID}/openhouse`}>Start</Link>
                            <Link className="HomeCardBottomLeftLeads" to={`/dashboard/${home.homeID}/leads`}>Leads</Link>
                        </div>
                        <div className="HomeCardBottomRight">
                            <FaEdit className="HomeCardIcon" onClick={() => {
                                setTargetHomeId(home.homeID);
                                setUpdateHomeModalOpen(true);
                            }} />
                            <FaTrashAlt className="HomeCardIcon" onClick={() => {
                                setTargetHomeId(home.homeID);
                                setDeleteHomeModalOpen(true);
                            }} />
                        </div>
                    </div>
                </animated.div>
            ))}
        </div>
    )
};

Homes.propTypes = {
    addHome: PropTypes.func.isRequired,
    deleteHome: PropTypes.func.isRequired,
    updateHome: PropTypes.func.isRequired,
    homes: PropTypes.arrayOf({
        homeID: PropTypes.string,
        title: PropTypes.string,
        link: PropTypes.string,
    }),
};

Homes.defaultProps = {
    homes: [],
};

export default Homes;
