import React from 'react';
import 'components/Help/Help.scss';

const Help = () => {

    return (
        <div className="HelpContainer">
            <div className="HelpTitle">Getting Started</div>
            <div className="HelpItems">
                <div className="HelpItem"><b>1.</b> Add each active listing you have to your dashboard by pressing "Add
                    Home". Include a helpful title and link for each listing.
                </div>
                <div className="HelpItem"><b>2.</b> Start an Open House by pressing "Start", and allow each client to sign
                    in using the digital form as they arrive.
                </div>
                <div className="HelpItem"><b>3.</b> After the Open House, you can easily view all your collected leads by
                    pressing "Leads" on the listing. You can also follow up with each lead by pressing "Follow Up" in the
                    leads view.
                </div>
            </div>
        </div>
    );
};

Help.propTypes = {};

export default Help;
