import React, { useState, useEffect } from 'react';

const MyInfoImage = ({ file }) => {
    const [loading, setLoading] = useState(false);
    const [thumb, setThumb] = useState(undefined);

    useEffect(() => {
        if (!file) return;
        setLoading(true);
    }, [file]);

    useEffect(() => {
        if (!loading) return;

        let reader = new FileReader();

        reader.onloadend = () => {
            setLoading(false);
            setThumb(reader.result);
        };

        console.log('file', file);

        reader.readAsDataURL(file);
    }, [loading]);

    if (!file) { return null; }
    if (loading) { return <p>loading...</p>; }
    return (<img src={thumb}
                 alt={file.name}
                 className="img-thumbnail mt-2"
                 height={200}
                 width={200} />);
};

export default MyInfoImage;
