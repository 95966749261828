import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import "components/common/Button/Button.scss";

const Button = ({
    className,
    onClick,
    title,
    outline,
    size
}) => (
    <div className={cx(
        "Button",
        {
            "Button--large": size === 'large',
            "Button--small": size === 'small',
            "Button--fill": !outline,
            "Button--outline": outline,
            [className]: !!className,
        }
    )} onClick={onClick}>
        { title }
    </div>
);

Button.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string,
    outline: PropTypes.bool,
    size: PropTypes.oneOf(["large", 'small']),
};

Button.defaultProps = {
    className: '',
    onClick: () => {},
    title: "",
    outline: false,
    size: "small",
};

export default Button;
