import React from 'react';
import { useSpring, animated, config } from 'react-spring';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from 'components/common/Button/Button.jsx';
import { generateLeadsCSV } from 'utils/generateCSV.js';
import LeadFollowUp from 'components/Leads/LeadFollowUp.jsx';
import 'components/Leads/Leads.scss';

const Leads = ({
    home,
    sendFollowUp
}) => {

    const leadAnimation = useSpring({
        to: {
            transform: 'translate(0%, 0%)',
            opacity: 1,
        },
        from: {
            transform: 'translate(0%, 0%)',
            opacity: 0,
        },
        delay: 0,
        config: config.default
    });

    const buttonAnimation = useSpring({
        to: {
            transform: 'translate(0%, 0%)',
            opacity: 1,
        },
        from: {
            transform: 'translate(-2%, 0%)',
            opacity: 0,
        },
        delay: 0,
        config: config.default
    });

    const titleAnimation = useSpring({
        to: {
            transform: 'translate(0%, 0%)',
            opacity: 1,
        },
        from: {
            transform: 'translate(2%, 0%)',
            opacity: 0,
        },
        delay: 0,
        config: config.default
    });

    return (
        <div className="Leads">
            <div className="LeadsTop">
                <div className="LeadsTitle">Leads</div>
                <animated.div className="LeadsSubtitile" style={titleAnimation}>{ home.title }</animated.div>
            </div>
            { home && home.leads.length > 0 && (
                <>
                    <table className="LeadsTable">
                        <tr>
                            <th className="LeadsTableHeader">Name</th>
                            <th className="LeadsTableHeader">Email</th>
                            <th className="LeadsTableHeader">Phone</th>
                            <th className="LeadsTableHeader">Follow Up</th>
                        </tr>
                        {home.leads.map((lead, index) => (
                            <animated.tr style={leadAnimation} key={index}>
                                <td>{lead.name}</td>
                                <td>{lead.email}</td>
                                <td>{lead.phone}</td>
                                <td>
                                    <LeadFollowUp followUpSent={lead.followUpSent} sendFollowUp={() => sendFollowUp(lead.email)} />
                                </td>
                            </animated.tr>
                        ))}
                    </table>
                    <animated.div className="LeadsExportOptions" style={buttonAnimation}>
                        <Button title="Download Leads" onClick={() => generateLeadsCSV(home.title, home.leads)} />
                    </animated.div>
                </>
            )}
            { home && home.leads.length === 0 && (
              <animated.div className="LeadsNoLeadsMessage" style={leadAnimation}>
                  There are no leads collected yet for this listing.
                  To collect leads, press 'start' on a house on your&nbsp;<Link className="LeadsNoLeadsLink" to="/dashboard"> dashboard</Link>.
              </animated.div>
            )}
        </div>
    );
};

Leads.propTypes = {
    home: PropTypes.any,
};

export default Leads;
