import React from 'react';
import PropTypes from 'prop-types';
import { useTransition, animated, config } from 'react-spring';
import { FaCheck } from 'react-icons/fa';
import Button from 'components/common/Button/Button.jsx';
import 'components/Leads/LeadFollowUp.scss';

const LeadFollowUp = ({ followUpSent, sendFollowUp }) => {

    const followUpTransitions = useTransition(followUpSent, null, {
        from: {
            transform: 'translateY(-50%)',
            position: 'absolute',
            opacity: 0,
        },
        enter: {
            opacity: 1,
        },
        leave: {
            opacity: 0,
        },
        config: config.default,
    });

    return (
        <div className="LeadFollowUpContainer">
            {followUpTransitions.map(({item, key, props}) => {
                return item ? (
                    <animated.div style={props} key={key}>
                        Follow Up Sent <FaCheck className="LeadFollowUpCheck" />
                    </animated.div>
                ) : (
                    <animated.div style={props} key={key}>
                        <Button title="Send Follow Up"
                                onClick={sendFollowUp}/>
                    </animated.div>
                )
            })}
        </div>
    );
};

LeadFollowUp.propTypes = {
    followUpSent: PropTypes.bool,
    sendFollowUp: PropTypes.func,
};

export default LeadFollowUp;
