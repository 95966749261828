import React, { useState} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { searchHomes } from 'utils/homes';
import Button from 'components/common/Button/Button.jsx';
import Homes from 'components/Homes/Homes.jsx';
import DashboardSearch from 'components/Dashboard/DashboardSearch.jsx';
import Help from 'components/Help/Help.jsx';
import 'components/Dashboard/Dashboard.scss';

const Dashboard = ({
    homes,
    addHome,
    deleteHome,
    updateHome,
}) => {
    const [searchQuery, setSearchQuery] = useState("");

    let queriedHomes = homes;
    if (searchQuery) queriedHomes = searchHomes(homes, searchQuery);

    return (
        <div className="Dashboard">
            <div className="DashboardTop">
                <div className="DashboardTitle">Dashboard</div>
                <Link to="/settings"><Button title="Settings" outline/></Link>
            </div>
            { homes && homes.length > 3  && <DashboardSearch searchQuery={searchQuery} setSearchQuery={setSearchQuery} /> }
            <Homes
                homes={searchQuery ? searchHomes(homes, searchQuery) : homes}
                addHome={addHome}
                deleteHome={deleteHome}
                updateHome={updateHome}
            />
            { queriedHomes && searchQuery && queriedHomes.length === 0 && <Button title="Clear Search" onClick={() => setSearchQuery("")}/>}
            { homes && homes.length < 3 && <Help />}
        </div>
        );
};

Dashboard.propTypes = {
    addHome: PropTypes.func.isRequired,
    deleteHome: PropTypes.func.isRequired,
    updateHome: PropTypes.func.isRequired,
    homes: PropTypes.arrayOf({
        homeID: PropTypes.string,
        title: PropTypes.string,
        link: PropTypes.string,
    }),
};

Dashboard.defaultProps = {
    homes: [],
};

export default Dashboard;
