import React, { useEffect } from 'react';
import {
    Switch,
    Route,
} from "react-router-dom";
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { tokenLogin } from 'actions/auth.js';
import { startPaymentSession } from 'actions/payment.js';
import Payment from 'components/Payment/Payment.jsx';

const PaymentContainer = (props) => {
    const {
        userID,
        jwt,
        authenticated,
        loadingAuth,
        subscribed,
        tokenLogin,
        startPaymentSession,
        loadingPaymentSession,
    } = props;

    let timeout;

    useEffect(() => {
        if (authenticated && jwt && !subscribed) {
            timeout = setInterval(() => tokenLogin(jwt), 2000);
        }
        return () => timeout && clearInterval(timeout);
    }, [subscribed]);

    if (loadingAuth && !authenticated) return null;
    if (!loadingAuth && !authenticated) return <Redirect to="/" />;
    if (!loadingAuth && authenticated && subscribed) return <Redirect to="/dashboard" />;
    return (
        <Switch>
            <Route path="/payment" component={() =>
                    <Payment
                        success={false}
                        loading={loadingPaymentSession}
                        startPaymentSession={startPaymentSession}
                    />
                }
               exact
            />
            <Route path="/payment/success" component={() =>
                    <Payment
                        success={true}
                        loading={loadingPaymentSession}
                        startPaymentSession={startPaymentSession}
                    />
                }
               exact
            />
        </Switch>
    );
};

const mapStateToProps = (state) => {
    return {
        userID: state.auth.userID,
        jwt: state.auth.jwt,
        authenticated: state.auth.authenticated,
        subscribed: state.auth.subscribed,
        loadingAuth: state.auth.loading,
        loadingPaymentSession: state.payment.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        tokenLogin,
        startPaymentSession,
    }, dispatch);
};

PaymentContainer.propTypes = {
    userID: PropTypes.string,
    jwt: PropTypes.string,
    subscribed: PropTypes.bool,
    loadingAuth: PropTypes.bool,
    loadingPaymentSession: PropTypes.bool,
    tokenLogin: PropTypes.func,
    startPaymentSession: PropTypes.func,
};

PaymentContainer.defautProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentContainer);

