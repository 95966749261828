import React from 'react';
import { Link } from 'react-router-dom';
import { FaEnvelope } from 'react-icons/fa';
import 'components/Footer/Footer.scss';

const Footer = () => (
    <div className="Footer">
        <a className="FooterSupport" href="mailto:support@openhomely.com"><FaEnvelope className="FooterIcon"/>Contact Support</a>
        <div className="FooterRight">
            <div className="FooterTerms"><Link to="/terms">Terms and Conditions</Link></div>
            <div className="FooterTerms"><Link to="/privacy">Privacy Policy</Link></div>
            <div className="FooterCopyright">© OpenHomely 2020</div>
        </div>
    </div>
);

Footer.propTypes = {
};

Footer.defaultProps = {
};

export default Footer;
