import axios from 'axios';
import qs from 'qs';

export const getHomes = (userID) => {
    const url = '/users/' + userID + '/homes';

    const requestConfig = {
        method: 'get',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const deleteHome = (userID, homeID) => {
    const url = '/users/' + userID + /homes/ + homeID;

    const requestConfig = {
        method: 'delete',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const addHome = (userID, title, link) => {
    console.log('addHome', userID, title, link);

    const url = '/users/' + userID + '/homes';

    const payload = qs.stringify({
        title,
        link: btoa(link),
    });

    const requestConfig = {
        data: payload,
        method: 'post',
        url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const updateHome = (userID, homeID, title, link) => {
    const url = '/users/' + userID + /homes/ + homeID;

    const payload = {
        title,
        link: btoa(link),
    };

    const requestConfig = {
        data: qs.stringify(payload),
        method: 'put',
        url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const captureLeadByHome = (userID, homeID, name, email, phone) => {
    const url = '/users/' + userID + /homes/ + homeID + '/leads';

    const payload = {
        email,
        name,
        phone
    };

    const requestConfig = {
        data: qs.stringify(payload),
        method: 'post',
        url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const sendFollowUp = (userID, homeID, email) => {
    const url = '/users/' + userID + /homes/ + homeID + '/leads/followup';

    const payload = {
        email,
    };

    const requestConfig = {
        data: qs.stringify(payload),
        method: 'post',
        url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
}
