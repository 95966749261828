import { tokenLogin } from 'actions/auth.js';
import * as PaymentService from 'services/payment.js';

export const START_PAYMENT_SESSION_REQUEST = 'START_PAYMENT_SESSION_REQUEST';
export const START_PAYMENT_SESSION_SUCCESS = 'START_PAYMENT_SESSION_SUCCESS';
export const START_PAYMENT_SESSION_ERROR = 'START_PAYMENT_SESSION_ERROR';

export const UNSUBSCRIBE_REQUEST = 'UNSUBSCRIBE_REQUEST';
export const UNSUBSCRIBE_SUCCESS = 'UNSUBSCRIBE_SUCCESS';
export const UNSUBSCRIBE_ERROR = 'UNSUBSCRIBE_ERROR';

export const startPaymentSession = () => {
    const request = PaymentService.startPaymentSession();

    return (dispatch) => {
        dispatch({
            type: START_PAYMENT_SESSION_REQUEST
        });

        request.then(
            payload => {
                try {
                    const stripe = window.Stripe('pk_test_4xKda7eesz1a5gx7wIiCgG1v');
                    stripe.redirectToCheckout({
                        sessionId: payload.data.sessionID
                    }).then((result) => dispatch({
                        type: START_PAYMENT_SESSION_SUCCESS,
                        payload: result,
                    }));
                } catch (e) {
                    // handle error.
                }
            }
        ).catch(
            error => dispatch({
                type: START_PAYMENT_SESSION_ERROR,
                error,
            })
        );
    };
};


export const unsubscribe = (jwt) => {
    const request = PaymentService.unsubscribe();

    return (dispatch) => {
        dispatch({
            type: UNSUBSCRIBE_REQUEST
        });

        request.then(
            payload => {
                tokenLogin(jwt);
                dispatch({
                    type: UNSUBSCRIBE_SUCCESS,
                    payload,
                })
            }
        ).catch(
            error => dispatch({
                type: UNSUBSCRIBE_ERROR,
                error,
            })
        );
    };
};
