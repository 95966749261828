import React from 'react';
import HeaderContainer from 'containers/HeaderContainer.jsx';
import RoutesContainer from 'containers/RoutesContainer.jsx';
import AuthContainer from 'containers/AuthContainer.jsx';
import FooterContainer from 'containers/FooterContainer.jsx';
import 'components/App/App.scss';

function App() {
    return (
        <AuthContainer>
            <div className="App">
                <HeaderContainer />
                <RoutesContainer />
                <FooterContainer />
            </div>
        </AuthContainer>
    );
}

export default App;
