import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout, deleteAccount } from '../actions/auth.js';
import { unsubscribe } from '../actions/payment.js';
import { getHomes } from "../actions/homes.js";
import Settings from 'components/Settings/Settings.jsx';

const SettingsContainer = (props) => {
    const {
        userID,
        jwt,
        authenticated,
        loadingAuth,
        homes,
        getHomes,
        logout,
        unsubscribe,
        deleteAccount
    } = props;

    useEffect(() => {
        if (authenticated && userID) {
            getHomes(userID);
        }
    }, [authenticated]);

    if (loadingAuth && !authenticated) return null;
    if (!loadingAuth && !authenticated) return <Redirect to="/" />;

    return (
        <Settings
            logout={logout}
            unsubscribe={() => unsubscribe(jwt)}
            deleteAccount={deleteAccount}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        userID: state.auth.userID,
        jwt: state.auth.jwt,
        homes: state.homes.homes,
        authenticated: state.auth.authenticated,
        loadingAuth: state.auth.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getHomes,
        logout,
        deleteAccount,
        unsubscribe,
    }, dispatch);
};

SettingsContainer.propTypes = {
    userID: PropTypes.string,
    loadingAuth: PropTypes.bool,
    getHomes: PropTypes.func,
    logout: PropTypes.func,
};

SettingsContainer.defautProps = {
    userID: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);
