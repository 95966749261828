import * as HomeService from 'services/homes.js';

export const GET_HOMES_REQUEST = 'GET_HOMES_REQUEST';
export const GET_HOMES_ERROR = 'GET_HOMES_ERROR';
export const GET_HOMES_SUCCESS = 'GET_HOMES_SUCCESS';

export const ADD_HOME_REQUEST = 'ADD_HOME_REQUEST';
export const ADD_HOME_ERROR = 'ADD_HOME_ERROR';
export const ADD_HOME_SUCCESS = 'ADD_HOME_SUCCESS';

export const DELETE_HOME_REQUEST = 'DELETE_HOME_REQUEST';
export const DELETE_HOME_ERROR = 'DELETE_HOME_ERROR';
export const DELETE_HOME_SUCCESS = 'DELETE_HOME_SUCCESS';

export const UPDATE_HOME_REQUEST = 'UPDATE_HOME_REQUEST';
export const UPDATE_HOME_ERROR = 'UPDATE_HOME_ERROR';
export const UPDATE_HOME_SUCCESS = 'UPDATE_HOME_SUCCESS';

export const CAPTURE_LEAD_REQUEST = 'CAPTURE_LEAD_REQUEST';
export const CAPTURE_LEAD_ERROR = 'CAPTURE_LEAD_ERROR';
export const CAPTURE_LEAD_SUCCESS = 'CAPTURE_LEAD_SUCCESS';

export const SEND_FOLLOW_UP_REQUEST = 'SEND_FOLLOW_UP_REQUEST';
export const SEND_FOLLOW_UP_ERROR = 'SEND_FOLLOW_UP_ERROR';
export const SEND_FOLLOW_UP_SUCCESS = 'SEND_FOLLOW_UP_SUCCESS';

export const getHomes = (userID) => {
    const request = HomeService.getHomes(userID);

    return (dispatch) => {
        dispatch({
            type: GET_HOMES_REQUEST
        });

        request.then(
            payload => dispatch({
                type: GET_HOMES_SUCCESS,
                payload,
            })
        ).catch(
            error => dispatch({
                type: GET_HOMES_ERROR,
                error,
            })
        );
    };
};

export const addHome = (userID, title, link) => {
    const request = HomeService.addHome(userID, title, link);

    return (dispatch) => {
        dispatch({
            type: ADD_HOME_REQUEST
        });

        request.then(
            payload => {
                dispatch(getHomes(userID));
                dispatch({
                    type: ADD_HOME_SUCCESS,
                    payload,
                });
            }
        ).catch(
            error => dispatch({
                type: ADD_HOME_ERROR,
                error,
            })
        );
    };
};

export const deleteHome = (userID, homeID) => {
    const request = HomeService.deleteHome(userID, homeID);

    return (dispatch) => {
        dispatch({
            type: DELETE_HOME_REQUEST
        });

        request.then(
            payload => {
                dispatch(getHomes(userID));
                dispatch({
                    type: DELETE_HOME_SUCCESS,
                    payload,
                });
            }
        ).catch(
            error => dispatch({
                type: DELETE_HOME_ERROR,
                error,
            })
        );
    };
};


export const updateHome = (userID, homeID, title, link) => {
    const request = HomeService.updateHome(userID, homeID, title, link);

    return (dispatch) => {
        dispatch({
            type: UPDATE_HOME_REQUEST
        });

        request.then(
            payload => {
                dispatch(getHomes(userID));
                dispatch({
                    type: UPDATE_HOME_SUCCESS,
                    payload,
                });
            }
        ).catch(
            error => dispatch({
                type: UPDATE_HOME_ERROR,
                error,
            })
        );
    };
};

export const captureLeadByHome = (userID, homeID, name, email, phone) => {
    const request = HomeService.captureLeadByHome(userID, homeID, name, email, phone);

    return (dispatch) => {
        dispatch({
            type: CAPTURE_LEAD_REQUEST
        });

        request.then(
            payload => {
                dispatch(getHomes(userID));
                dispatch({
                    type: CAPTURE_LEAD_SUCCESS,
                    payload,
                });
            }
        ).catch(
            error => dispatch({
                type: CAPTURE_LEAD_ERROR,
                error,
            })
        );
    };
};

export const sendFollowUp = (userID, homeID, email) => {
    const request = HomeService.sendFollowUp(userID, homeID, email);

    return (dispatch) => {
        dispatch({
            type: SEND_FOLLOW_UP_REQUEST
        });

        request.then(
            payload => {
                dispatch(getHomes(userID));
                dispatch({
                    type: SEND_FOLLOW_UP_SUCCESS,
                    payload,
                });
            }
        ).catch(
            error => dispatch({
                type: SEND_FOLLOW_UP_ERROR,
                error,
            })
        );
    };
};

