import React from 'react';
import PropTypes from 'prop-types';
import {
    Switch,
    Route,
    useParams,
} from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Landing from 'containers/LandingContainer.jsx';
import Login from 'containers/LoginContainer.jsx';
import Signup from 'containers/SignupContainer.jsx';
import Dashboard from 'containers/DashboardContainer.jsx';
import OpenHouse from 'containers/OpenHouseContainer.jsx';
import Leads from 'containers/LeadsContainer.jsx';
import Settings from 'containers/SettingsContainer.jsx';
import Payment from 'containers/PaymentContainer.jsx';
import ResetPassword from 'containers/ResetPasswordContainer.jsx';
import Terms from 'components/Terms/Terms.jsx';
import Privacy from 'components/Privacy/Privacy.jsx';

const RoutesContainer = () => (
    <Switch>
        <Route path="/" component={Landing} exact />
        <Route path="/login" component={Login} exact />
        <Route path="/signup" component={Signup} exact />
        <Route path="/resetpassword" component={ResetPassword} exact />
        <Route path="/dashboard" component={Dashboard} exact />
        <Route path="/settings" component={Settings} exact />
        <Route path="/payment" component={Payment} />
        <Route path="/dashboard/:homeID/openhouse" component={OpenHouse} exact />
        <Route path="/dashboard/:homeID/leads" component={Leads} exact />
        <Route path="/terms" component={Terms} exact />
        <Route path="/privacy" component={Privacy} exact />
    </Switch>
);

const mapStateToProps = (state) => {
    return {
        name: state.auth.name,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
};

RoutesContainer.propTypes = {
    name: PropTypes.string,
};

RoutesContainer.defautProps = {
    name: ""
};

export default connect(mapStateToProps, mapDispatchToProps)(RoutesContainer);
