import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'components/common/Button/Button.jsx';
import {
    FaDoorOpen,
    FaSms,
    FaMailBulk,
    FaFileDownload,
    FaRedo,
} from 'react-icons/fa';
import cx from 'classnames';
import home from 'assets/home.jpg';
import { useSpring, config, animated } from 'react-spring';
import VisibilitySensor from 'react-visibility-sensor';
import 'components/Landing/Landing.scss';

const Landing = () => {

    const bgImageAnimation = useSpring({
        to: {
            transform: 'translateY(50px)',
            filter: 'blur(10px)',
        },
        from: {
            transform: 'translateY(0px)',
            filter: 'blur(0px)',
        },
        delay: 2000,
        config: config.gentle,
    });

    const textBubbleAnimation = useSpring({
        to: {
            transform: 'translateY(-75px)',
            opacity: 1,
        },
        from: {
            transform: 'translateY(0px)',
            opacity: 0,
        },
        repeat: 10,
        delay: 2500,
        config: config.gentle,
    });

    const watchVideoAnimation = useSpring({
        to: {
            transform: 'translateY(100px)',
            opacity: 1,
        },
        from: {
            transform: 'translateY(0px)',
            opacity: 0,
        },
        repeat: 10,
        delay: 3500,
        config: config.gentle,
    });

    return (
        <div className="Landing">
            <section className="LandingSectionTop">
                <div className="LandingLeft">
                    <animated.img className="LandingLeftImg" style={bgImageAnimation} src={home} alt="Beautiful Home"/>
                    <animated.div className="LandingLeftTextBubble" style={textBubbleAnimation}>
                        Hello Paul Stanford,<br /><br />
                        Welcome to 73 Waterside Terrace.<br /><br />
                        The listings link is: http://www.realty.com/home/1230<br /><br />
                        Thank you for coming!<br />
                    </animated.div>
                    <animated.div className="LandingLeftDemoVideo" style={watchVideoAnimation}>
                        <Button title="Watch Demo" outline />
                    </animated.div>
                </div>
                <div className="LandingRight">
                    <div className="LandingRightTitle">The best agents offer the best Open Home experiences.</div>
                    <div className="LandingRightSubtitle">OpenHomely <span className="LandingRightSubtitle--emphasize">captures leads</span> at the door, automatically <span className="LandingRightSubtitle--emphasize">texts and emails</span> them a link to your
                        properties website or brochure, and easily <span className="LandingRightSubtitle--emphasize">allows you to follow up</span> with them after.
                    </div>
                    {/*<div className="LandingRightPrice">No more sign in sheets, no nonsense, completely simple</div>*/}
                    <div className="LandingRightPrice">Welcome to the future of Real Estate.</div>
                    {/*<div className="LandingRightPrice">Just 10$/month. Cancel anytime.</div>*/}
                    <div className="LandingRightCta">
                        <Link to="/signup"><Button title="Sign Up" /></Link>
                    </div>
                </div>
            </section>
            <section className="LandingSectionHowItWorks">
                <div className="LandingFeaturesTitle">How It Works</div>
                <div className="LandingHowItWorksBlock">
                    <VisibilitySensor>
                        {({isVisible}) => (
                            <>
                                <div
                                    className={cx(
                                        "LandingHowItWorksBlockItem",
                                        {
                                            "LandingHowItWorksBlockItem--offsetLeft": isVisible,
                                        }
                                    )}
                                >
                                    <div className="LandingHowItWorksBlockItem--number">1</div>Add each active listing you have to your dashboard.
                                </div>
                                <div
                                    className={cx(
                                        "LandingHowItWorksBlockItem",
                                        {
                                            "LandingHowItWorksBlockItem--offsetRight": isVisible,
                                        }
                                    )}
                                >
                                    <div className="LandingHowItWorksBlockItem--number">2</div>Start an Open House, and allow each client to sign in using the digital form as they arrive.
                                </div>
                                <div
                                    className={cx(
                                        "LandingHowItWorksBlockItem",
                                        {
                                            "LandingHowItWorksBlockItem--offsetLeft": isVisible,
                                        }
                                    )}
                                >
                                    <div className="LandingHowItWorksBlockItem--number">3</div>Watch each client enjoy as they receive a professionally crafted text message and email thanking them for their arrival, including the provided link to the properties website or brochure as well as your contact information.
                                </div>
                                <div
                                    className={cx(
                                        "LandingHowItWorksBlockItem",
                                        {
                                            "LandingHowItWorksBlockItem--offsetRight": isVisible,
                                        }
                                    )}
                                >
                                    <div className="LandingHowItWorksBlockItem--number">4</div>After the Open House, you can easily follow up by sending each lead a professional thank you message, which includes your contact information as well as the provided link to the properties website or brochure.
                                </div>
                            </>
                        )}
                    </VisibilitySensor>
                </div>
            </section>
            <section className="LandingSectionFeatures">
                <div className="LandingFeaturesTitle">Features</div>
                <div className="LandingFeaturesList">
                    <div className="LandingFeatureItem">
                        <FaDoorOpen className="LandingFeatureItemIcon" />
                        <div className="LandingFeatureItemDescription">Collects new leads when they arrive at your open house.</div>
                    </div>
                    <div className="LandingFeatureItem">
                        <FaSms className="LandingFeatureItemIcon" />
                        <div className="LandingFeatureItemDescription">Automatically texts each lead a link to your properties website or brochure.</div>
                    </div>
                    <div className="LandingFeatureItem">
                        <FaMailBulk className="LandingFeatureItemIcon" />
                        <div className="LandingFeatureItemDescription">Automatically emails each lead a link to your properties website or brochure.</div>
                    </div>
                    <div className="LandingFeatureItem">
                        <FaRedo className="LandingFeatureItemIcon" />
                        <div className="LandingFeatureItemDescription">Follow up with each lead after your open house, saving you time.</div>
                    </div>
                    <div className="LandingFeatureItem">
                        <FaFileDownload className="LandingFeatureItemIcon" />
                        <div className="LandingFeatureItemDescription">Download the leads you collect.</div>
                    </div>
                </div>
            </section>
            <section className="LandingSectionPricing">
                <div className="LandingFeaturesTitle">Pricing</div>
                <div className="LandingPricingCard">
                    <div className="LandingPricingCardTitle">Just&nbsp;<div className="LandingPricingCardTitlePrice">$10</div></div>
                    <div className="LandingPricingCardSubtitle">Per month, billed monthly.</div>
                </div>
                <div className="LandingPricingCTATitle">Ready to elevate your business to the next level?</div>
                <div className="LandingPricingCTAButton">
                    <Link to="/signup"><Button title="Sign Up" /></Link>
                </div>
            </section>
        </div>
    );
};

export default Landing;
