import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login } from "../actions/auth";
import Login from 'components/Login/Login.jsx';

const LoginContainer = (props) => {
    const {
        login,
        authenticated,
        error,
    } = props;

    if (authenticated) return <Redirect to="/dashboard" />;
    return <Login login={login} error={error} />;
};

const mapStateToProps = (state) => {
    return {
        name: state.auth.name,
        email: state.auth.email,
        error: state.auth.error,
        authenticated: state.auth.authenticated,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        login,
    }, dispatch);
};

LoginContainer.propTypes = {
    login: PropTypes.func.isRequired,
};

LoginContainer.defautProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
