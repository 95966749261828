import React from 'react';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';
import 'components/Dashboard/DashboardSearch.scss';

const DashboardSearch = ({
    setSearchQuery,
    searchQuery,
}) => {

    return (
        <div className="DashboardSearchWrapper">
            <FaSearch className="DashboardSearchIcon" />
            <input
                className="DashboardSearchInput"
                placeholder="Search"
                value={searchQuery}
                onBlur={(e) => setSearchQuery(e.target.value)}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
        </div>
    )
};

DashboardSearch.propTypes = {
    setSearchQuery: PropTypes.func.isRequired,
    searchQuery: PropTypes.string.isRequired,
}


export default DashboardSearch;
