import axios from 'axios';
import qs from 'qs';

export const login = (email, password) => {
    const url = '/auth/login';

    const requestData = {
        email,
        password,
    };

    const requestConfig = {
        data: qs.stringify(requestData),
        method: 'post',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const tokenLogin = (jwt) => {
    const url = '/auth/tokenLogin';

    const requestData = {
        jwt
    };

    const requestConfig = {
        data: qs.stringify(requestData),
        method: 'post',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const deleteAccount = () => {
    const url = '/auth/deleteaccount';

    const requestConfig = {
        method: 'delete',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const signup = (name, email, password) => {
    const url = '/auth/signup';

    const requestData = {
        name,
        email,
        password
    };

    const requestConfig = {
        data: qs.stringify(requestData),
        method: 'post',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const resetPasswordRequest = (email) => {
    const url = '/auth/resetpasswordrequest';

    const requestData = {
        email
    };

    const requestConfig = {
        data: qs.stringify(requestData),
        method: 'post',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const resetPassword = (email, password, hash) => {
    const url = '/auth/resetpassword';

    const requestData = {
        email,
        password,
        hash,
    };

    const requestConfig = {
        data: qs.stringify(requestData),
        method: 'post',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};
