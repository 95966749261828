import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Footer from 'components/Footer/Footer.jsx';

const FooterContainer = (props) => (
    <Footer name={props.name} />
);

const mapStateToProps = (state) => {
    return {
        name: state.auth.name,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
};

FooterContainer.propTypes = {
    name: PropTypes.string,
};

FooterContainer.defautProps = {
    name: ""
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer);
