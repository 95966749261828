import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string'
import { bindActionCreators } from 'redux';
import { resetPasswordRequest, resetPassword } from "../actions/auth";
import ResetPassword from 'components/ResetPassword/ResetPassword.jsx';

const ResetPasswordContainer = (props) => {
    const {
        resetPassword,
        resetPasswordRequest,
        error,
        location,
    } = props;

    const { hash } = queryString.parse(location.search);

    // if (authenticated) return <Redirect to="/dashboard" />;
    return <ResetPassword
        resetPasswordRequest={resetPasswordRequest}
        resetPassword={resetPassword}
        error={error}
        hash={hash}
    />;
};

const mapStateToProps = (state) => {
    return {
        name: state.auth.name,
        email: state.auth.email,
        error: state.auth.error,
        authenticated: state.auth.authenticated,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        resetPassword,
        resetPasswordRequest
    }, dispatch);
};

ResetPasswordContainer.propTypes = {
    login: PropTypes.func.isRequired,
};

ResetPasswordContainer.defautProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer);
