import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getHomes, sendFollowUp } from "../actions/homes.js";
import Leads from 'components/Leads/Leads.jsx';

const LeadsContainer = (props) => {
    const {
        userID,
        authenticated,
        loadingAuth,
        homes,
        getHomes,
        sendFollowUp,
    } = props;

    const { homeID } = useParams();

    useEffect(() => {
        if (authenticated && userID) {
            getHomes(userID);
        }
    }, [authenticated]);

    const home = homes.find((home) => home.homeID === homeID);

    if (loadingAuth && !authenticated) return null;
    if (!loadingAuth && !authenticated) return <Redirect to="/" />;

    return <Leads home={home} sendFollowUp={(email) => sendFollowUp(userID, homeID, email)} />;
};

const mapStateToProps = (state) => {
    return {
        userID: state.auth.userID,
        homes: state.homes.homes,
        authenticated: state.auth.authenticated,
        loadingAuth: state.auth.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getHomes,
        sendFollowUp,
    }, dispatch);
};

LeadsContainer.propTypes = {
    userID: PropTypes.string,
    homeID: PropTypes.string,
    loadingAuth: PropTypes.bool,
    addHome: PropTypes.func.isRequired,
    captureLeadByHome: PropTypes.func.isRequired,
};

LeadsContainer.defautProps = {
    userID: '',
    homeID: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadsContainer);
