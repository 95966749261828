import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import Button from 'components/common/Button/Button.jsx';
import 'components/Homes/AddUpdateHome.scss';

const AddHome = ({
   handleSubmit,
   handleChange,
   handleBlur,
   values,
   touched,
   errors,
     isUpdate,
}) => (
    <div className="AddHomeContainer">
        <div className="AddHome">
            <div className="AddHomeFormTitle">{ isUpdate ? "Update Home" : "Add Home" }</div>
            <form className="AddHomeForm" onSubmit={handleSubmit} autocomplete="off">
                <div className="AddHomeFormInputTitle">Title</div>
                <input
                    className="AddHomeFormInput"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    name="title"
                />
                {errors.title && touched.title && <div className="AddHomeFormInputFeedback">{errors.title}</div>}
                <div className="AddHomeFormInputTitle">Link</div>
                <input
                    className="AddHomeFormInput"
                    type="url"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.link}
                    name="link"
                />
                {errors.link && touched.link && <div className="AddHomeFormInputFeedback">{errors.link}</div>}
                <button className="AddHomeFormButton" type="submit"><Button title={isUpdate ? "Update Home" : "Add Home"} /></button>
            </form>
        </div>
    </div>
);

const AddHomeForm = withFormik({
    mapPropsToValues: (props) => {
        return props.isUpdate ? { title: props.title, link: props.link } : { title: '', link: '' };
    },

    validate: values => {
        const errors = {};

        if (!values.title) {
            errors.title = 'A title is required.';
        }

        if (!values.link) {
            errors.link = 'A link is required.';
        }

        return errors;
    },

    handleSubmit: (values, { setSubmitting, props }) => {
        if (props.isUpdate) {
            props.updateHome(values.title, values.link);
        } else {
            props.addHome(values.title, values.link);
        }
        setSubmitting(false);
    },

    displayName: 'AddHomeForm',
})(AddHome);

AddHome.propTypes = {
    name: PropTypes.string,
    errors: PropTypes.any,
    handleSubmit: PropTypes.func.isRequired,
    isUpdate: PropTypes.bool,
    updateHome: PropTypes.func.isRequired,
    addHome: PropTypes.func.isRequired,
    title: PropTypes.string,
    link: PropTypes.string,
};

AddHome.defaultProps = {
    isUpdate: false,
    name: '',
    title: '',
    link: ''
};

export default AddHomeForm;
