import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getHomes, addHome, updateHome, deleteHome } from "../actions/homes.js";
import Dashboard from 'components/Dashboard/Dashboard.jsx';

const DashboardContainer = (props) => {
    const {
        userID,
        authenticated,
        loadingAuth,
        subscribed,
        getHomes,
        addHome,
        updateHome,
        deleteHome,
        homes,
    } = props;

    useEffect(() => {
        if (authenticated && userID) {
            getHomes(userID);
        }
    }, [authenticated]);

    if (loadingAuth && !authenticated) return null;
    if (!loadingAuth && !authenticated) return <Redirect to="/" />;
    if (!loadingAuth && authenticated && !subscribed) return <Redirect to="/payment" />;
    return (
        <Dashboard
            homes={homes}
            addHome={(title, link) => addHome(userID, title, link)}
            updateHome={(homeID, title, link) => updateHome(userID, homeID, title, link)}
            deleteHome={(homeID) => deleteHome(userID, homeID)}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        userID: state.auth.userID,
        homes: state.homes.homes,
        authenticated: state.auth.authenticated,
        subscribed: state.auth.subscribed,
        loadingAuth: state.auth.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getHomes,
        addHome,
        updateHome,
        deleteHome,
    }, dispatch);
};

DashboardContainer.propTypes = {
    userID: PropTypes.string,
    subscribed: PropTypes.bool,
    loadingAuth: PropTypes.bool,
    addHome: PropTypes.func,
};

DashboardContainer.defautProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
