import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTransition, animated, config } from 'react-spring';
import { FaCheck } from 'react-icons/fa';
import { withFormik } from 'formik';
import Button from 'components/common/Button/Button.jsx';
import 'components/OpenHouse/OpenHouse.scss';

const OpenHouse = ({
   house,
   handleSubmit,
   handleChange,
   handleBlur,
   values,
   touched,
   errors,
   isUpdate,
}) => {

    const [showSuccess, setShowSuccess] = useState(false);

    const toggleSuccess = () => {
      setShowSuccess(true);
      setTimeout(() => {
          setShowSuccess(false);
      }, 5000)
    };

    const transitions = useTransition(showSuccess, null, {
        from: {
            position: 'absolute',
            transform: 'translate(0%, 5%)',
            opacity: 0,
        },
        enter: {
            transform: 'translate(0%, 0%)',
            opacity: 1,
        },
        leave: {
            transform: 'translate(0%, 5%)',
            opacity: 0,
        },
        config: config.default,
    });

    return (
        <div className="OpenHomeWrapper" >
            {transitions.map(({ item, key, props }) => {
                return !item ? (
                    <animated.div className="OpenHome" style={props} key={key}>
                        <div className="OpenHomeTitle">Welcome,</div>
                        <div className="OpenHomeSubtitleRow">
                            <div className="OpenHomeSubtitle">Please Sign In</div>
                            <div className="OpenHomeAddress">{ house && house.title }</div>
                        </div>
                        <form className="OpenHomeForm" onSubmit={handleSubmit}>
                            <div className="OpenHomeFormInputTitle">Name</div>
                            <input
                                className="OpenHomeFormInput"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                name="name"
                            />
                            {errors.name && touched.name && <div className="AddHomeFormInputFeedback">{errors.name}</div>}
                            <div className="OpenHomeFormInputTitle">Email</div>
                            <input
                                className="OpenHomeFormInput"
                                type="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                name="email"
                            />
                            {errors.email && touched.email && <div className="OpenHomeFormInputFeedback">{errors.email}</div>}
                            <div className="OpenHomeFormInputTitle">Phone Number</div>
                            <input
                                className="OpenHomeFormInput"
                                type="tel"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                name="phone"
                            />
                            {errors.phone && touched.phone && <div className="OpenHomeFormInputFeedback">{errors.phone}</div>}
                            <div className="OpenHomeCheckboxWrapper">
                                <input
                                    className="OpenHomeFormCheckboxInput"
                                    type="checkbox"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.phone}
                                    name="agreement"
                                />
                                <div className="OpenHomeCheckboxDescription">I agree to the terms and conditions.</div>
                            </div>
                            <button className="OpenHomeFormButton" type="submit" onClick={toggleSuccess}><Button title="Sign In" /></button>
                        </form>
                    </animated.div>
                ) : (
                    <animated.div style={props} key={key}>
                        <FaCheck className="OpenHomeSuccessIcon" />
                        <div className="OpenHomeSuccessText">Signed in successfully.</div>
                    </animated.div>
                )
            })}
        </div>
    )
};

const OpenHouseForm = withFormik({
    mapPropsToValues: () => {
        return { name: '', email: '', phone: '' };
    },

    validate: values => {
        const errors = {};

        if (!values.name) {
            errors.name = 'A name is required.';
        }

        if (!values.email) {
            errors.email = 'An email is required.';
        }

        if (!values.phone) {
            errors.phone = 'A phone number is required.';
        }

        return errors;
    },

    handleSubmit: (values, { setSubmitting, props, resetForm }) => {
        props.captureLeadByHome(values.name, values.email, values.phone);
        resetForm();
        setSubmitting(false);
    },

    displayName: 'OpenHouseForm',
})(OpenHouse);

OpenHouse.propTypes = {
    house: PropTypes.any,
    captureLeadByHome: PropTypes.func.isRequired,
};

export default OpenHouseForm;
