import axios from 'axios';
import {
    LOGOUT,
    LOGIN_REQUEST,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    SIGNUP_REQUEST,
    SIGNUP_ERROR,
    SIGNUP_SUCCESS,
    TOKEN_LOGIN_REQUEST,
    TOKEN_LOGIN_ERROR,
    TOKEN_LOGIN_SUCCESS,
    REQUEST_RESET_PASSWORD_REQUEST,
    REQUEST_RESET_PASSWORD_SUCCESS,
    REQUEST_RESET_PASSWORD_ERROR,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    DELETE_ACCOUNT_REQUEST,
    DELETE_ACCOUNT_ERROR,
    DELETE_ACCOUNT_SUCCESS,
} from 'actions/auth.js';

export const initialState = {
    jwt: localStorage.getItem('jwt') || '',
    name: '',
    email: '',
    userID: '',
    subscribed: false,
    payment: {
        success: false,
        stripe_session: '',
    },
    loading: false,
    error: null,
    authenticated: false,
};

export default function authReducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOGOUT:
        case RESET_PASSWORD_SUCCESS:
        case DELETE_ACCOUNT_SUCCESS:
            localStorage.removeItem('jwt');
            window.location.href = '/';
            return initialState;
        case LOGIN_REQUEST:
        case SIGNUP_REQUEST:
        case TOKEN_LOGIN_REQUEST:
        case REQUEST_RESET_PASSWORD_REQUEST:
        case RESET_PASSWORD_REQUEST:
        case DELETE_ACCOUNT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case LOGIN_ERROR:
        case SIGNUP_ERROR:
        case TOKEN_LOGIN_ERROR:
        case REQUEST_RESET_PASSWORD_ERROR:
        case RESET_PASSWORD_ERROR:
        case DELETE_ACCOUNT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case LOGIN_SUCCESS:
        case SIGNUP_SUCCESS:
        case TOKEN_LOGIN_SUCCESS:
            localStorage.setItem('jwt', action.payload.data.jwt);
            axios.defaults.headers.common.jwt = action.payload.data.jwt;
            return {
                ...state,
                loading: false,
                error: null,
                name: action.payload.data.name,
                email: action.payload.data.email,
                userID: action.payload.data.userID,
                subscribed: action.payload.data.subscribed,
                payment: {
                    success: action.payload.data.payment.success,
                    stripe_session: action.payload.data.payment.stripe_session,
                },
                jwt: action.payload.data.jwt,
                authenticated: true,
            };
        default:
            return state;
    }
}
