import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getHomes, captureLeadByHome } from "../actions/homes.js";
import OpenHouse from 'components/OpenHouse/OpenHouse.jsx';

const OpenHomeContainer = (props) => {
    const {
        userID,
        authenticated,
        loadingAuth,
        homes,
        getHomes,
        captureLeadByHome,
    } = props;

    const { homeID } = useParams();

    useEffect(() => {
        if (authenticated && userID) {
            getHomes(userID);
        }
    }, [authenticated]);

    if (loadingAuth && !authenticated) return null;
    if (!loadingAuth && !authenticated) return <Redirect to="/" />;
    return (
        <OpenHouse
            house={homes.find((home) => home.homeID === homeID)}
            captureLeadByHome={(name, email, phone) => captureLeadByHome(userID, homeID, name, email, phone)}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        userID: state.auth.userID,
        homes: state.homes.homes,
        authenticated: state.auth.authenticated,
        loadingAuth: state.auth.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getHomes,
        captureLeadByHome,
    }, dispatch);
};

OpenHomeContainer.propTypes = {
    userID: PropTypes.string,
    homeID: PropTypes.string,
    loadingAuth: PropTypes.bool,
    addHome: PropTypes.func.isRequired,
    captureLeadByHome: PropTypes.func.isRequired,
};

OpenHomeContainer.defautProps = {
    userID: '',
    homeID: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenHomeContainer);
